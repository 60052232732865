<template>
<div :id="'popup-entity-'+entity._id">
  <v-list v-if="entity != null">
    <v-list-item>
        <v-list-item-avatar height="46" width="46"
            :class="'mr-3'">
          <v-gravatar height="30" style="border-radius:50%; margin-bottom:-6px;"
                      :default-img="'retro'" :email="entity.name"/>
        </v-list-item-avatar>
        
        <v-list-item-content>

          <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
            <b href="javascript:" text 
              style="text-transform:none;font-size:0.7em!important;text-decoration:none;white-space:normal;display:inline;" 
                  :class="'pa-0'">
                  <v-icon  style="margin-bottom:3px;margin-right:-5px;margin-left:-4px;">mdi-account</v-icon>
                  {{ entity.name }}
            </b>
          </v-list-item-title>
          
          <!-- <v-list-item-subtitle v-show="entity.address==null &&  entity.city==null">
            <small><v-icon x-small>mdi-map-marker</v-icon> {{ $vuetify.lang.t('$vuetify.map.notLocated') }}</small>
          </v-list-item-subtitle> -->

          <v-list-item-subtitle class="mb-1" v-if="entity.category!=null">
            <v-icon small style="margin-bottom:3px;margin-right:-1px;">fa fa-{{ entity.category.iconFa }}</v-icon>
            {{ entity.category.name }}
          </v-list-item-subtitle>

          <v-list-item-subtitle v-show="entity.address!=null ||  entity.city!=null">
            <v-icon small style="margin-bottom:3px;margin-left:-1px;">mdi-map-marker</v-icon>
            {{ entity.address }} {{ entity.city }}
            à {{ getDistance() }}km
          </v-list-item-subtitle>

        </v-list-item-content>
    </v-list-item>

  
    <v-row class="mx-0 pt-4 px-3" v-if="entity.dataType == 'company'">
      <v-col cols="12" class="pt-0 px-1">
        <v-btn  v-for="i in 1, entity.employees.length" class="mr-1 mb-2" 
                x-small icon color="green" 
                :key="i+'y'" outlined>
          <v-icon style="font-size:13px!important;" class="mb-0">mdi-account-tie</v-icon>
        </v-btn>
        <v-btn  v-for="i in 1, nbEmployeeNeeded" class="mr-1 mb-2" 
                x-small icon color="teal" 
                :key="i+'x'" outlined>
          <v-icon style="font-size:13px!important;" class="mb-0">mdi-account-tie</v-icon>
        </v-btn>
        <br>
        <b class="teal--text pt-2">
          {{ nbEmployeeNeeded }} poste{{ nbEmployeeNeeded>1?'s':'' }} à pourvoir
        </b>
        <v-divider class="mt-3"/>
      </v-col>
    </v-row>
  
    <v-row v-if="entity.dataType == 'company' && entity.category.key != 'service' && entity.productsOut.length > 0"
           class="mx-0 py-5 pt-1 px-3" >
      <v-col cols="12" class="pt-0 px-2 pb-2">
        <v-icon  class="mb-1">mdi-store</v-icon>
        <v-icon small class="mr-">mdi-chevron-down</v-icon>
        <b>Produits disponibles à la vente</b>
      </v-col>
      <template v-for="(product, key) in entity.productsOut">
          <v-chip outlined :key="key+'-out'"  
                :color="product.iconColor"
                :title="product.name"
                class="ma-1 py-0 pl-2"
                style="height:26px;">
            <v-icon :color="product.iconColor" class="mr-1">
              mdi-{{ product.icon }}
            </v-icon>
              {{ parseInt(product.stock) }} 
              {{ product.priceUnity }}{{ parseInt(product.stock) > 1 ? "s" : "" }}
          </v-chip>
      </template>
    </v-row>

    <v-row v-if="entity.dataType == 'company' && entity.category.key == 'service' && entity.productsOut.length > 0"
           class="mx-0 py-5 pt-1 px-3" >
      <v-col cols="12" class="pt-0 px-2 pb-2">
        <v-icon  class="mb-1">mdi-store</v-icon>
        <v-icon small class="mr-">mdi-chevron-down</v-icon>
        <b>Services proposés</b>
      </v-col>
      <template v-for="(product, key) in entity.productsOut">
          <v-chip outlined :key="key+'-out'"  
                :color="product.iconColor"
                :title="product.name"
                class="ma-1 py-0 pl-2"
                style="height:26px;">
            <v-icon :color="product.iconColor" class="mr-1">
              mdi-{{ product.icon }}
            </v-icon>
              {{ product.name }}
          </v-chip>
      </template>
    </v-row>

    <v-col cols="12" class="py-1">
      <v-btn block class="" elevation="0" :id="'btn-show-details-entity-' + entity._id">Afficher les détails</v-btn>
    </v-col>


  </v-list>

</div>
</template>


<script>

import core from '../../plugins/core.js'
import Gravatar from 'vue-gravatar'

export default {
  components: {
    'v-gravatar' : Gravatar,
  },
  props: ['entity'],

  methods: {
    getDistance(){
      return core.getDistance(this.$store.state.auth.user.coordinates, this.entity.coordinates)
    },
  },

  computed: {
    nbEmployeeNeeded(){
      //console.log("nbEmployeeNeeded", this.entity.employees.length)
      if(this.entity == null) return 0
      return (this.entity.surface / this.entity.surfaceParcelle * this.entity.nbEmployeesByParcelle) - (this.entity.employees.length + 1) 
    }, 
  },

}

</script>